body {
  background-color: black;
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
}

/* .swal2-popup {
  color: #facb07;
  background-color: #1a1c1d !important;
}
.css-1e2nqws-Holder .css-xnsa8s-Popup-Popup-Box {
  width: 25vw !important;
}
.css-1e2nqws-Holder {
  width: 25vw !important;
}
@media only screen and (max-width: 768px) {
  .css-1e2nqws-Holder {
    width: 80vw !important;
  }
  .css-1e2nqws-Holder .css-xnsa8s-Popup-Popup-Box {
    width: 80vw !important;
  }
} */
